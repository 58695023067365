import React, { useEffect, useState } from 'react';

import HeaderSubPages from '../../../components/header/header-sub-pages';
import { useUserContext } from '../../../context/user-context';
import '../../../assets/css/my-team-style.css';

const ProfilePage = () => {
    const { user } = useUserContext();
    const [copied, setCopied] = useState(null);

    const handleCopy = (text, index) => {
        navigator.clipboard.writeText(text);
        setCopied(index);
        setTimeout(() => setCopied(null), 2000); // Reset copied state after 2 seconds
    };
    useEffect(() => {
        document.title = 'Profile - Bhangra Premier League - Red FM';
    }, []);
    //console.log(user);
    return (
        <>
            <HeaderSubPages pageName={'Profile Details'} />

            <section className='mt-3 min-h-screen'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='team-main-box'>
                                <div className='team-single-box'>
                                    <div className='content-right text-white'>
                                        <div className='d-grid gap-3'>
                                            <p className='mb-1 d-flex gap-1'>
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person text-warning" vie text-warningwBox="0 0 16 16">
                                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                                    </svg>
                                                </span>
                                                {user.participant_name}
                                            </p>
                                            <p className='mb-1 d-flex gap-1'>
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-buildings text-warning" viewBox="0 0 16 16">
                                                        <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z" />
                                                        <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z" />
                                                    </svg>
                                                </span>
                                                {user.city_name} | {user.state_name}
                                            </p>
                                            <p className='mb-1 d-flex gap-1'>
                                                <span>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-people text-warning" viewBox="0 0 16 16">
                                                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                                                    </svg>
                                                </span>
                                                {user?.group_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* {user?.group_name?.split(',').map((item, i) => {
                                    return (
                                        <div className='team-single-box' key={i}>
                                            <div className='team-img-rounded'></div>
                                            <div className='content-right'>
                                                <h3 className='team-name'>{item}</h3>
                                                <h4 className='team-position'>{i === 0 ? 'Captain' : 'Partner'}</h4>
                                            </div>
                                        </div>
                                    );
                                })} */}
                                {user?.socialLinks?.split(",").map((item, i) => (
                                    <div className="team-single-box" key={i}>
                                        <div className="content-right d-flex gap-3 align-items-center">
                                            <h3 className="team-name">{item}</h3>
                                            <div onClick={() => handleCopy(item, i)} className="copy-btn">
                                                {copied === i ? "Copied!" : "Copy"}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className='text-center'>
                                    <a href={'/user/profile/edit'} className='btn-theme btn-secondary text-center'>Edit</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProfilePage;
