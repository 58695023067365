import React, { useEffect, useRef, useState } from 'react';
import HeaderSubPages from '../../components/header/header-sub-pages';
import img1 from '../../assets/icons/avatar.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSearchParams } from 'react-router-dom';
import { useUserContext } from '../../context/user-context';
import service, { methods } from '../../service';
import '../../assets/css/entries-style.css';
import WinnerSolo from '../../assets/images/highlights/winners-season-2/winner-solo.jpg';
import RunnerUpSolo from '../../assets/images/highlights/winners-season-2/runner-up-solo.jpg';
import WinnerGroupMusicCategory from '../../assets/images/highlights/winners-season-2/winner-group-music-category.jpg';
import RunnerUpGroupMusicCategory from '../../assets/images/highlights/winners-season-2/runner-up-group-music-category.jpg';
import WinnerGroupFolkLiveCategory from '../../assets/images/highlights/winners-season-2/winner-group-folk-live-category.jpg';
import RunnerUpGroupFolkLiveCategory from '../../assets/images/highlights/winners-season-2/runner-up-group-folk-live-category.jpg';

const PER_PAGE = 15;
const Winners2025 = () => {
    const [searchParams] = useSearchParams();
    const [secData, setSecData] = useState([]);
    const [search] = useState(searchParams.get('search') || '');
    const [debouncedSearch] = useState(search);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    // const { handleOpenVote } = useAuthContext();
    const { user, userType } = useUserContext();

    const debounceTimeoutRef = useRef(null);


    const fetchWinnerEntries = async (search, page) => {
        setIsLoading(true);
        try {
            const res = await service({
                url: 'winners/list/',
                method: methods.GET,
                params: { seasonID: 2, page: page, item_per_page: PER_PAGE, searchkeyword: search },
            });
            if (res.totalCount <= page * PER_PAGE) {
                setHasMore(false);
            }
            setSecData((prev) => [...prev, ...res.payload]);
        } catch (error) {
            setSecData([]);
            // toast.error(error);
            console.error(`Error :: fetchWinnerEntries :: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };
    // console.log(secData);
    useEffect(() => {
        const handleScroll = () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }

            debounceTimeoutRef.current = setTimeout(() => {
                const scrollY = window.scrollY || window.pageYOffset;
                const windowHeight = window.innerHeight;
                const documentHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);

                const threshold = 100;

                if (scrollY + windowHeight >= documentHeight - threshold && !isLoading && hasMore) {
                    setPage((prev) => prev + 1);
                }
            }, 200);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [user, userType, hasMore, isLoading]);

    useEffect(() => {
        fetchWinnerEntries(debouncedSearch, page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, debouncedSearch]);

    useEffect(() => {
        document.title = 'Winners of 2025 - Bhangra Premier League - Red FM';
    }, []);
    function getSocialPlatform(link) {
        if (link.includes('instagram.com')) {
            return 'Instagram link';
        } else if (link.includes('facebook.com')) {
            return 'Facebook link';
        } else if (link.includes('x.com')) {
            return 'X link';
        } else if (link.includes('youtu')) {
            return 'Youtube link';
        } else {
            return 'Other link';
        }
    }
    return (
        <>
            <HeaderSubPages pageName='Winners of 2025' />
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-12'>
                            <div className='subpage-main-box'>

                                {/* winner category A end*/}
                                <div className='row g-4 mt-2'>
                                    <div className='col-12'>
                                        <h2 className='text-dark-red mb-0 h3'>Winners</h2>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='img-box'>
                                            <img src={WinnerSolo} className='w-100' alt='winner of 2024' />
                                            <h3 className='text-yellow h6 mt-3'>Vishal Bhatia - (Solo Category)                                            </h3>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='img-box'>
                                            <img src={WinnerGroupMusicCategory} className='w-100' alt='winner of 2024' />
                                            <h3 className='text-yellow h6 mt-3'>Harvinder singh (Nikka) - (Group Music Category) </h3>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='img-box'>
                                            <img src={WinnerGroupFolkLiveCategory} className='w-100' alt='winner of 2024' />
                                            <h3 className='text-yellow h6 mt-3'>Mohindra College Patiala - (Group Folk Live Category)</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* winner category A end*/}
                                {/* Runner-up  start*/}
                                <div className='row g-4 mt-2'>
                                    <div className='col-12'>
                                        <h2 className='text-dark-red mb-0 h3'>Runner-up </h2>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className='img-box'>
                                            <img src={RunnerUpSolo} className='w-100' alt='winner of 2024' />
                                            <h3 className='text-yellow h6 mt-3'>Manav - (Solo category)</h3>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className='img-box'>
                                            <img src={RunnerUpGroupMusicCategory} className='w-100' alt='winner of 2024' />
                                            <h3 className='text-yellow h6 mt-3'>Dehradun Bhangra Club - (Group Music Category)</h3>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className='img-box'>
                                            <img src={RunnerUpGroupFolkLiveCategory} className='w-100' alt='winner of 2024' />
                                            <h3 className='text-yellow h6 mt-3'>DAV College Jallandhar - (Group Folk Live Category)</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* winner category A end*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*  if no data found in first time */}
            {secData.length === 0 && !isLoading && (
                <section>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-10'>
                                <div className='subpage-main-box'>
                                    <div className="d-flex align-items-start justify-content-center vh-100 py-4">
                                        <h2 className="text-white text-center mb-5">Watch out this space for update</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <section className='py-4'>
                <div className='container min-vh-100'>
                    <div className="row g-3 ">
                        {secData.map((item, index) => (
                            <div key={index} className='col-lg-4'>
                                <div className='card-entries p-2'>
                                    <div className='row g-2 lg:g-3'>
                                        <div className='col-lg-2 col-2'>
                                            <div className='img-box'>
                                                {item.profile_pic ? (
                                                    <img src={item.profile_pic} className='w-100' alt='img' />
                                                ) : (
                                                    <img src={img1} className='w-100' alt='img' />
                                                )
                                                }
                                            </div>
                                        </div>
                                        <div className='col-lg-10 col-10'>
                                            <h2 className='text-uppercase text-truncate'>{item.participant_name}</h2>
                                            <p className='text-secondary mb-0 text-truncate' title={item.city_name}>
                                                <small>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-buildings" viewBox="0 0 16 16">
                                                        <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z" />
                                                        <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z" />
                                                    </svg> </small>
                                                <small className='text-white'>
                                                    {item.city_name ? (
                                                        <>{item.city_name}</>
                                                    ) : (
                                                        <>NA</>
                                                    )
                                                    }
                                                </small>
                                            </p>
                                            <p className='text-secondary mb-0 text-truncate' title={item.categoryName}><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-badge" viewBox="0 0 16 16">
                                                <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                                <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492z" />
                                            </svg> </small> <small className='text-white'>{item.categoryName}</small></p>
                                            <div className='text-end mt-2'>
                                                {item?.socialLinks?.split(",").length > 1 ? (
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="success" className='btn btn-explore w-50' id="dropdown-basic">
                                                            Explore
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {item?.socialLinks?.split(",").map((link, i) => (
                                                                <Dropdown.Item key={i} href={link.trim()} target='_blank' rel="noopener noreferrer">
                                                                    {getSocialPlatform(link.trim())}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                ) : (
                                                    <a href={item?.socialLinks?.trim()} className='btn btn-explore w-50' target="_blank" rel="noopener noreferrer">
                                                        Explore
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {isLoading && (
                            <div className="text-center py-4">
                                <div className="spinner-border border-warning" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                        {/* {!hasMore && secData.length > 0 && (
                            <div className="text-center py-4 text-light">
                                No more winner to load
                            </div>
                        )} */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Winners2025;
