import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import OpenEnrollmentIcon from '../../assets/icons/open-enrollment.svg';
import ArrowDownIcon from '../../assets/icons/arrow-down-s-line.svg';
import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import service, { methods } from '../../service';
import { VOTE } from '../../constants';
import CrossIconWhite from '../../assets/icons/x-white.svg';
import PlusIcon from '../../assets/icons/plus.svg';
import CrossIcon from '../../assets/icons/x.svg';

const schema = yup.object({
    categoryID: yup.string().required('Category is required'),
    //state_id: yup.string().required('State is required'),
    //city_id: yup.string().required('City is required'),
    //contactNumber: yup.string().optional(),
    socialLinks: yup.array()
        .of(
            yup.object().shape({
                url: yup
                    .string()
                    .matches(/^https:\/\/[^\s]+$/i, 'Invalid URL, must start with https://')
                    .required('Social Link is required'),
            })
        )
        .max(4, 'Maximum 4 social links allowed')
}).required();


const StepFour = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);

    const { register, handleSubmit, formState, watch, setValue, control } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            socialLinks: [{ url: '' }]
        }
    });
    const selectedCategoryID = watch('categoryID');

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'socialLinks'
    });
    const { handleNextStepParticipate, stepOneDetails, handleClose } = useAuthContext();
    const { user, userType } = useUserContext();
    const { errors } = formState;
    const selectState = watch('state_id');

    const onParticipateDetails = async (data) => {
        setIsLoading(true);
        //delete data.contactNumber;

        // Convert socialLinks array to a comma-separated string
        const formattedSocialLinks = data.socialLinks
            .map(link => link.url.trim())
            .filter(url => url) // Remove empty values
            .join(', '); // Convert to comma-separated string

        const updatedData = {
            ...data,
            socialLinks: formattedSocialLinks, // Now it's "https://facebook.com, https://instagram.com"
        };

        try {
            const res = await service({
                url: 'participant/update-details/',
                method: methods.POST,
                data: updatedData,
            });
            if (res?.status === 1) {
                handleNextStepParticipate(5);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
            console.error(`Error :: onParticipateDetails :: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCategory = async () => {
        try {
            const res = await service({
                url: 'category/list?seasonID=2',
                method: methods.GET,
            });
            setCategory(res.payload);
        } catch (error) {
            toast.error(error);
            console.error(`Error :: fetchCategory :: ${error}`);
        }
    };

    const fetchState = async () => {
        try {
            const res = await service({
                url: 'states/',
                method: methods.GET,
            });
            setState(res.payload);
        } catch (error) {
            toast.error(error);
            console.error(`Error :: fetchState :: ${error}`);
        }
    };

    useMemo(() => {
        const find = state.find((item) => item.state_id === Number(selectState));
        if (find?.state_cities.length > 0) {
            setCity(find.state_cities);
            setValue('city_id', undefined, { shouldValidate: true });
        }
    }, [selectState, setValue, state]);

    useEffect(() => {
        fetchCategory();
        fetchState();
    }, []);

    useMemo(() => {
        if (stepOneDetails?.phone) {
            setValue('contactNumber', stepOneDetails?.phone);
        }
        if (userType === VOTE && user) {
            setValue('contactNumber', user);
        }
    }, [setValue, stepOneDetails?.phone, user, userType]);
    // Social Links Handlers

    return (
        <div className='content-box'>
            <div className='row justify-content-center align-items-start vh-100'>
                <div className='col-lg-12 pb-3'>
                    <div className='content-top  mb-3 pt-5 pt-lg-0'>
                        <div className='steps-dash'>
                            <div className='step active'></div>
                            <div className='step'></div>
                        </div>
                        <div className='mb-3'>
                            <img src={OpenEnrollmentIcon} className='img-fluid' alt='OpenEnrollmentIcon' />
                        </div>
                        <h2 className='text-white'>
                            <span className='d-block'>Participant Details</span>
                        </h2>
                        <button className='text-x' onClick={handleClose}>
                            <img src={CrossIcon} className='img-fluid' alt='cross-icon' />
                        </button>
                    </div>
                    <div className='login-form-box'>
                        <form onSubmit={handleSubmit(onParticipateDetails)} className='d-block'>
                            <input type='hidden' value={'2'} {...register('seasonID')} />
                            <div className='form-floating mb-3'>
                                <img src={ArrowDownIcon} className='arrow-select' alt='ArrowDownIcon' />
                                <select className='form-select' {...register('categoryID')}>
                                    <option value=''>Select Category</option>
                                    {category.map((item) => {
                                        return (
                                            <option key={item.categoryID} value={item.categoryID}>
                                                {item.categoryName}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>Category</label>
                                {errors.categoryID && <p className='mt-2 text-white'>{errors.categoryID.message}</p>}
                            </div>
                            {selectedCategoryID !== '4' && (
                                <div className='form-floating mb-3'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        {...register('group_name')}
                                        placeholder=' '
                                    />
                                    <label>Team Participant Names (separate by comma) / Solo</label>
                                    {errors.group_name && <p className='mt-2 text-white'>{errors.group_name.message}</p>}
                                </div>
                            )}

                            <div className='form-floating mb-3'>
                                <img src={ArrowDownIcon} className='arrow-select' alt='ArrowDownIcon' />
                                <select className='form-select' {...register('state_id')}>
                                    <option value=''>Select State</option>
                                    {state.map((item) => {
                                        return (
                                            <option key={item.state_id} value={item.state_id}>
                                                {item.state_name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>State</label>
                                {errors.state_id && <p className='mt-2 text-white'>{errors.state_id.message}</p>}
                            </div>

                            <div className='form-floating mb-3'>
                                <img src={ArrowDownIcon} className='arrow-select' alt='ArrowDownIcon' />
                                <select className='form-select' {...register('city_id')}>
                                    <option value=''>Select City</option>
                                    {city.map((item) => {
                                        return (
                                            <option key={item.city_id} value={item.city_id}>
                                                {item.city_name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>City</label>
                                {errors.city_id && <p className='mt-2 text-white'>{errors.city_id.message}</p>}
                            </div>
                            {/* Social Links */}
                            <div className='mb-3'>
                                <p className="font-bold text-white">Social Links</p>
                                {fields.map((link, index) => (
                                    <div key={link.id} className="d-flex align-items-center gap-3 mb-3">
                                        <div className='form-floating flex-grow-1'>
                                            <input
                                                type="text"
                                                {...register(`socialLinks.${index}.url`)}
                                                className='form-control'
                                            />
                                            <label>Link {index + 1}</label>
                                            {errors.socialLinks?.[index]?.url && (
                                                <p className='mt-2 text-white'>{errors.socialLinks[index].url.message}</p>
                                            )}
                                        </div>
                                        {fields.length > 1 && (
                                            <button type="button" onClick={() => remove(index)} className="btn btn-warning">
                                                <img src={CrossIconWhite} alt='icon' />
                                            </button>
                                        )}
                                    </div>
                                ))}
                                <div className="text-center">
                                    {fields.length < 4 && (
                                        <button type="button" onClick={() => append({ url: '' })} className="btn btn-warning">
                                            <img src={PlusIcon} alt='icon' />
                                        </button>
                                    )}
                                </div>
                            </div>



                            {/* <div className='form-floating mb-3'>
                                <input
                                    type='number'
                                    className='form-control'
                                    {...register('contactNumber')}
                                    placeholder=''
                                    disabled
                                />
                                <label>Contact Number</label>
                            </div> */}

                            <button
                                className={`w-100 btn-theme btn-secondary text-center ${isLoading && 'disable-btn'}`}
                                type='submit'
                                disabled={isLoading}
                            >
                                Next
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepFour;
