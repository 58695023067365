import React, { useState } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { toast } from 'react-toastify';
import Avatar from '../../assets/icons/avatar.svg';
import IcoLogout from '../../assets/icons/logout.svg';
import IcoShop from '../../assets/icons/shop.svg';
import IcoIno from '../../assets/icons/info.svg';
import logo from '../../assets/images/logo-season2.png';
import '../../assets/css/header-style.css';
import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import { PARTICIPANT, VOTE } from '../../constants';

const HeaderDesktop = () => {
    const location = useLocation();
    const { handleOpenParticipate } = useAuthContext();
    const { user, userType, logout } = useUserContext();

    const [showEntries, setShowEntries] = useState(false);
    const [showHighlights, setShowHighlights] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);

    const handleMouseEnter = (key) => {
        setOpenDropdown(key);
    };

    const handleMouseLeave = () => {
        setOpenDropdown(null);
    };
    const handleLogout = () => {
        logout();
        toast('User logged out successfully');
    };

    const handleEntriesShow = () => setShowEntries(true);
    const handleEntriesHide = () => setShowEntries(false);
    const handleHighlightsShow = () => setShowHighlights(true);
    const handleHighlightsHide = () => setShowHighlights(false);

    return (
        <header className='d-none d-lg-block'>
            <Navbar collapseOnSelect expand='lg' className='bg-transparent nav-desktop'>
                <Container>
                    <Navbar.Brand as={Link} to='/'>
                        <img src={logo} alt='img' width='140px' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className='m-auto' activeKey={location.pathname}>
                            <Nav.Link eventKey='/' as={Link} to='/'>
                                Home
                            </Nav.Link>
                            <Nav.Link eventKey='/about-us' as={Link} to='/about-us'>
                                About
                            </Nav.Link>
                            <NavDropdown
                                title='Entries'
                                className='basic-nav-dropdown'
                                show={showEntries}
                                onMouseEnter={handleEntriesShow}
                                onMouseLeave={handleEntriesHide}

                            >

                                <NavDropdown.Item eventKey='/entries-2025' to='/entries-2025' as={NavLink}>
                                    Season 2025
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='/entries' to='/entries' as={NavLink}>
                                    Season 2024
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link eventKey='/judges' as={Link} className='me-1' to='/judges'>
                                Judges
                            </Nav.Link>
                            <NavDropdown
                                title='Highlights'
                                className='basic-nav-dropdown'
                                show={showHighlights}
                                onMouseEnter={handleHighlightsShow}
                                onMouseLeave={handleHighlightsHide}
                            >


                                <DropdownButton
                                    as={ButtonGroup}
                                    key="end2025"
                                    id="dropdown-button-drop-end2025"
                                    drop="end"
                                    variant="secondary"
                                    title="Season 2025"
                                    className="btn-tp"
                                    show={openDropdown === "end2025"}
                                    onMouseEnter={() => handleMouseEnter("end2025")}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <NavDropdown.Item eventKey="/winners-2025" to="/winners-2025" as={Link}>Winners</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="/best-of-2025" to="/best-of-2025" as={Link}>Best of Season</NavDropdown.Item>
                                </DropdownButton>
                                <NavDropdown.Divider />
                                <DropdownButton
                                    as={ButtonGroup}
                                    key="end2024"
                                    id="dropdown-button-drop-end2024"
                                    drop="end"
                                    variant="secondary"
                                    title="Season 2024"
                                    className="btn-tp"
                                    show={openDropdown === "end2024"}
                                    onMouseEnter={() => handleMouseEnter("end2024")}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <NavDropdown.Item eventKey="/winners-2024" to="/winners-2024" as={Link}>Winners</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="/best-of-2024" to="/best-of-2024" as={Link}>Best of Season</NavDropdown.Item>
                                </DropdownButton>
                            </NavDropdown>
                            <Nav.Link eventKey='/merchandise' as={Link} className='me-1' to='/merchandise'>
                                Shop Punjab
                            </Nav.Link>
                            <Nav.Link eventKey='/sponsor' as={Link} className='me-1' to='/sponsor'>
                                Sponsor
                            </Nav.Link>
                            <Nav.Link eventKey='/faqs' as={Link} className='me-1' to='/faqs'>
                                FAQs
                            </Nav.Link>
                            {userType === VOTE && (
                                <button className='nav-link text-dark-red' onClick={handleLogout}>
                                    <img src={IcoLogout} className='img-fluid me-1' alt='IcoLogout' />
                                    Logout
                                </button>
                            )}
                        </Nav>
                        <Nav>
                            {(userType !== PARTICIPANT || !userType) && (
                                <Nav.Link className='btn-theme btn-secondary text-center' onClick={handleOpenParticipate}>
                                    Login/Participate
                                </Nav.Link>
                            )}
                        </Nav>
                        {user && userType === PARTICIPANT && (
                            <NavDropdown title={<img src={Avatar} alt='mdo' width='40' height='40' className='rounded-circle' />} id='dropdown-basic'>
                                <NavDropdown.Item as={Link} to='/user/profile'>
                                    <span className='me-2 d-inline-block'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-person-fill text-warning" viewBox="0 0 16 16">
                                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                        </svg>
                                    </span>
                                    My Profile
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/merchandise'>
                                    <img src={IcoShop} className='img-fluid me-2' alt='IcoIno' /> Merchandise
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/about-us'>
                                    <img src={IcoIno} className='img-fluid me-2' alt='IcoIno' /> About Us
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item className='text-dark-red' onClick={handleLogout}>
                                    <img src={IcoLogout} className='img-fluid me-2' alt='IcoIno' /> Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default HeaderDesktop;
