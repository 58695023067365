import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';
// import hl1 from '../../assets/images/highlights/1.jpg';
import hl2 from '../../assets/images/highlights/2.jpg';
import hl3 from '../../assets/images/highlights/3.jpg';
// import hl4 from '../../assets/images/highlights/4.jpg';
import hl5 from '../../assets/images/highlights/5.jpg';
import hl6 from '../../assets/images/highlights/6.jpg';
import hl7 from '../../assets/images/highlights/7.jpg';
import hl8 from '../../assets/images/highlights/8.jpg';
import hl9 from '../../assets/images/highlights/9.jpg';
import hl10 from '../../assets/images/highlights/10.jpg';
import hl11 from '../../assets/images/highlights/11.jpg';
import hl12 from '../../assets/images/highlights/12.jpg';
import hl13 from '../../assets/images/highlights/13.jpg';
import hl14 from '../../assets/images/highlights/14.jpg';
import hl15 from '../../assets/images/highlights/15.jpg';
import hl16 from '../../assets/images/highlights/16.jpg';

import BestOf1 from '../../assets/images/highlights/ALL-WINNERS-OF-SEASON-1.jpg';
import BestOf2 from '../../assets/images/highlights/BPL-IMAGE-1.jpg';
import BestOf3 from '../../assets/images/highlights/MANAV-SHARMA-1.jpg';
import BestOf4 from '../../assets/images/highlights/best-1.jpg';
import BestOf5 from '../../assets/images/highlights/best-2.jpg';
import BestOf6 from '../../assets/images/highlights/best-3.jpg';
import BestOf7 from '../../assets/images/highlights/best-4.jpg';
import BestOf8 from '../../assets/images/highlights/best-5.jpg';
// import BestOf9 from '../../assets/images/highlights/best-6.jpg';
import BestOf10 from '../../assets/images/highlights/best-7.jpg';
// import ReactPlayer from 'react-player';


function BestOf2024() {
    useEffect(() => {
        document.title = 'Best Of 2024 - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='Best Of 2024' />
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='subpage-main-box'>
                                <div className='row g-4 mt-2'>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl2} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl3} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl5} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl6} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl7} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl8} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl9} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl10} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl11} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl12} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl13} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl14} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl15} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl16} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={BestOf1} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='img-box'>
                                            <img src={BestOf2} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='img-box'>
                                            <img src={BestOf3} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf4} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf5} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf6} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf7} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf8} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf10} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                </div>
                                {/* winner category A end*/}
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BestOf2024;
