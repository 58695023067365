import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import IcoCheckSuccess from '../../assets/icons/check-success.svg';
import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import service, { methods } from '../../service';
import { PARTICIPANT } from '../../constants';

const StepSix = () => {
    const { handleClose } = useAuthContext();
    const { setUser, setUserType } = useUserContext();
    
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const res = await service({
                    url: 'profile/participant/',
                    method: methods.GET,
                });
                if (res.status === 1) {
                    setUser(res.payload);
                    setUserType(PARTICIPANT);

                }
            } catch (error) {
                toast.error(error);
                console.error(`Error :: fetchUserDetails :: ${error}`);
            }
        };
        setTimeout(() => {
            fetchUserDetails();
        }, 3000);
    }, [setUser, setUserType]);

    const handleCloseClick = () => {
        handleClose();
    }
    return (
        <div className='content-box bg-green'>
            <div className='row justify-content-center align-items-center '>
                <div className='col-lg-12'>
                    <div className='login-form-box text-light text-center'>
                        <div className='col-3 m-auto'>
                            <img src={IcoCheckSuccess} alt='IcoCheckSuccess' className='m-auto w-100' />
                        </div>
                        <h3 className='mt-3'>Thankyou!</h3>
                        <p>You have been registered successfully</p>
                        <div className='text-start'>
                            <p><small>In case your entry is shortlisted, you will receive a call from our team.
                            </small>
                                <small>Thanks for your patience, for more details please refer to <a href={'/terms-and-conditions'} className={'text-warning'}>Terms & Conditions</a>.</small></p>
                        </div>
                        <button className='btn btn-warning w-100 py-2 my-3' onClick={handleCloseClick}>Okay</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepSix;
