import { Route, Routes } from 'react-router-dom';
import React from 'react';

import TermsAndConditions from '../pages/terms-and-conditions';
import PrivacyPolicy from '../pages/privacy-policy';
import Merchandise from '../pages/merchandise';
// import MyVideos from '../pages/my-videos';
import AboutUs from '../pages/about-us';
import Entries from '../pages/entries';
import Sponsor from '../pages/sponsor';
// import MyTeam from '../pages/my-team';
import Judges from '../pages/judges';
import Home from '../pages/home';
import Faqs from '../pages/faqs';
import Winners2024 from '../pages/highlights/winners-2024';
import BestOf2024 from '../pages/highlights/best-of-2024';
import Entries2025 from '../pages/entries-2025';
import Winners2025 from '../pages/highlights/winners-2025';
import BestOf2025 from '../pages/highlights/best-of-2025';
import ProfilePage from '../pages/user/profile';
import ProfileEdit from '../pages/user/profile/edit-profile';

const Router = () => {
    return (
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/user/profile' element={<ProfilePage />} />
            <Route exact path='/user/profile/edit' element={<ProfileEdit />} />
            <Route exact path='/entries' element={<Entries />} />
            <Route exact path='/entries-2025' element={<Entries2025 />} />
            {/* <Route exact path='/my-videos' element={<MyVideos />} /> */}
            {/* <Route exact path='/my-team' element={<MyTeam />} /> */}
            <Route exact path='/judges' element={<Judges />} />
            <Route exact path='/about-us' element={<AboutUs />} />
            <Route exact path='/faqs' element={<Faqs />} />
            <Route exact path='/merchandise' element={<Merchandise />} />
            <Route exact path='/terms-and-conditions' element={<TermsAndConditions />} />
            <Route exact path='/sponsor' element={<Sponsor />} />
            <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route exact path='/winners-2024' element={<Winners2024 />} />
            <Route exact path='/winners-2025' element={<Winners2025 />} />
            <Route exact path='/best-of-2024' element={<BestOf2024 />} />
            <Route exact path='/best-of-2025' element={<BestOf2025 />} />
        </Routes>
    );
};

export default Router;
