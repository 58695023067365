import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';
import '../../assets/css/faq-style.css';

const jsonData = [
    {
        "id": "02",
        "title": "Is there any cost for the registration process?",
        "description": "No. The registration is free of cost."
    },
    {
        "id": "03",
        "title": "Is there any time limit for uploading video?",
        "description": "You can share the video-link of a maximum of 2 – 2.5 minute performance. The video should not be older than 6 months from the current date of registration."
    },
    {
        "id": "04",
        "title": "Can I edit my link after I have submitted my form?",
        "description": "Yes. You may edit your details by logging-in again. However, make sure you correctly submit all your details and re-check once by visiting your profile page after logging-in."
    },
    {
        "id": "05",
        "title": "Is dress code required while uploading video?",
        "description": "Presentation has points to be selected in the Top 5 of each category."
    },
    {
        "id": "06",
        "title": "Will I get any confirmation after uploading video for auditions?",
        "description": "No. Only selected candidates will be contacted by team Red FM for the grand-finale."
    },
    {
        "id": "07",
        "title": "What is the minimum age to participate in Bhangra Premier League?",
        "description": "A person should be equal to or above 8 years to participate. However, in case selected for the grand-finale, a written consent form signed by the participant’s parents/guardians may be required at any time before the actual performance."
    },
    {
        "id": "08",
        "title": "Which are the Audition venues?",
        "description": "Finale will be held in either Chandigarh. Selected candidates will be notified."
    },
    {
        "id": "09",
        "title": "What are the dates & timings for finale?",
        "description": "The finale dates along with venue details are given on our website. Time for the finale is strictly between 3PM to 10 PM."
    },
    {
        "id": "10",
        "title": "Is dress code required for the finale?",
        "description": "Every Participant(s) shall bring their respective costumes and dance equipment for the finale."
    },
    {
        "id": "11",
        "title": "Can I upload multiple videos?",
        "description": "An applicant can register only once in a category. However, they can submit more than one video-link of their recent performances to enhance their chance of being selected."
    },
    {
        "id": "12",
        "title": "Can I participate alone or with the team?",
        "description": "We have kept 3 categories, they are – Solo Bhangra, Group Folk Live Bhangra, Group Music (Recorded) Bhangra. Yes, you can participate alone also (Solo Category). One person can participate in one category only."
    },
    {
        "id": "13",
        "title": "Should I bring my props for the finale?",
        "description": "Yes, you have to bring your props."
    },
    {
        "id": "14",
        "title": "Why can’t I make any changes?",
        "description": "After you have completed your registration, or the closing date has passed you will be unable to make changes - no exceptions."
    },
    {
        "id": "15",
        "title": "Can I participate even if I’m not from Punjab?",
        "description": "Yes. Participation is open for everyone from any state in India."
    },
    {
        "id": "16",
        "title": "For outstation participants will there be any travel and stay provision?",
        "description": "No. All stay and travel expenses to be borne by the participants."
    },
    {
        "id": "17",
        "title": "Will I get any participation certificate?",
        "description": "Yes, you will get a participation certificate."
    },
    {
        "id": "18",
        "title": "Is there any prize money for the winner?",
        "description": "Yes, there will be prize money. Details will be disclosed on the website."
    }
]


const Faqs = () => {
    useEffect(() => {
        document.title = 'FAQ - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='FAQs' />
            {/* <div className='container'>
                        <div className="d-flex align-items-start justify-content-center vh-100">
                            <h1 className="text-white text-center mt-5">Watch out this space for update</h1>
                        </div>
                    </div> */}
            <section className='mt-3'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='subpage-main-box'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='accordion faq-accordion' id='accordionFaq'>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header collapsed' id='headingOne'>
                                                    <button
                                                        className='accordion-button'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseOne'
                                                        aria-expanded='true'
                                                        aria-controls='collapseOne'
                                                    >
                                                        <div className='number'>01</div>
                                                        How can I participate?
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseOne'
                                                    className='accordion-collapse collapse show'
                                                    aria-labelledby='headingOne'
                                                    data-bs-parent='#accordionFaq'
                                                >
                                                    <div className='accordion-body'>
                                                        You can participate by simply clicking on the registration link and fill in your details. After that you are to provide your video link. 
                                                    </div>
                                                </div>
                                            </div>
                                            {jsonData.map((item) => (
                                                <div className='accordion-item' key={item.id}>
                                                    <h2 className='accordion-header' id={`heading${item.id}`}>
                                                        <button
                                                            className='accordion-button collapsed'
                                                            type='button'
                                                            data-bs-toggle='collapse'
                                                            data-bs-target={`#collapse${item.id}`}
                                                            aria-expanded='false'
                                                            aria-controls={`collapse${item.id}`}
                                                        >
                                                            <div className='number'>{item.id}</div> {item.title}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={`collapse${item.id}`}
                                                        className='accordion-collapse collapse'
                                                        aria-labelledby={`heading${item.id}`}
                                                        data-bs-parent='#accordionFaq'
                                                    >
                                                        <div className='accordion-body'>{item.description}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 text-white mt-3'>
                                        <h2>Schedule </h2>
                                        <ul>
                                            <li><strong>Registration</strong> – Last date 17<sup>th</sup> March 2025 </li>
                                            <li><strong>FINALE</strong> – – 22<sup>nd</sup> March 2025</li>
                                            <li><strong>Venue</strong>: Chandigarh Tricity</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faqs;
