import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { useAuthContext } from './context/auth.context';
import StepThree from './components/auth/step-three';
import StepFour from './components/auth/step-four';
// import StepFive from './components/auth/step-five';
import StepTwo from './components/auth/step-two';
import StepSix from './components/auth/step-six';
import StepOne from './components/auth/step-one';
// import CrossIcon from './assets/icons/x.svg';
import Footer from './components/footer';
import Router from './router';

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
}

function App() {
    const { isVote, voteStep, isParticipate, participateStep } = useAuthContext();
    const location = useLocation();

    useEffect(() => {
        if (isVote || isParticipate) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isParticipate, isVote]);

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
            title: location.pathname,
        });
    }, [location]);
    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
          (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = true;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
      
          window.fbq("init", "1019536773357295");
          window.fbq("track", "PageView");
        }
      }, []);
    return (
        <>
            {/* Router */}
            <Router />

            {/* Vote step */}
            <div className={`content-registration ${isVote ? 'show-registration' : 'hide-registration'}`}>
                {voteStep === 1 && <StepOne />}
                {voteStep === 2 && <StepTwo />}

                <div className='overly-bg'></div>
                {/* <button className='text-x' onClick={handleClose}>
                    <img src={CrossIcon} className='img-fluid' alt='cross-icon' />
                </button> */}
            </div>

            {/* Participation step */}
            <div className={`content-registration ${isParticipate ? 'show-registration' : 'hide-registration'}`}>
                {participateStep === 1 && <StepOne />}
                {participateStep === 2 && <StepTwo />}
                {participateStep === 3 && <StepThree />}
                {participateStep === 4 && <StepFour />}
                {/* {participateStep === 5 && <StepFive />} */}
                {participateStep === 5 && <StepSix />}

                <div className='overly-bg'></div>
                {/* <button className='text-x' onClick={handleClose}>
                    <img src={CrossIcon} className='img-fluid' alt='cross-icon' />
                </button> */}
            </div>

            <Footer />
        </>
    );
}

export default App;
