import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';

// import BestOf1 from '../../assets/images/highlights/ALL-WINNERS-OF-SEASON-1.jpg';




function BestOf2025() {
    useEffect(() => {
        document.title = 'Best Of 2025 - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='Best Of 2025' />
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='subpage-main-box'>
                                <div className="d-flex align-items-start justify-content-center vh-100 py-4">
                                    <h2 className="text-white text-center mb-5">Watch out this space for update</h2>
                                </div>
                                {/* <div className='row g-4 mt-2'>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl2} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl3} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl5} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl6} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl7} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl8} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl9} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl10} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl11} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl12} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl13} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl14} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl15} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={hl16} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='img-box'>
                                            <img src={BestOf1} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='img-box'>
                                            <img src={BestOf2} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='img-box'>
                                            <img src={BestOf3} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf4} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf5} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf6} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf7} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf8} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-6'>
                                        <div className='img-box'>
                                            <img src={BestOf10} className='w-100' alt='winner of 2024' />
                                        </div>
                                    </div>
                                </div> */}
                                {/* winner category A end*/}
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BestOf2025;
