import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';

import GdGoenkaLogo from '../../assets/images/sponsor/gd-goenka.jpg';
import AmityLogo from '../../assets/images/sponsor/amity.jpg';
import PizzaHutLogo from '../../assets/images/sponsor/pizza-hut.jpg';
import SwarajLogo from '../../assets/images/sponsor/swaraj-tractors.jpg';
import XuvLogo from '../../assets/images/sponsor/xuv-3xo.jpg';
import HlpLogo from '../../assets/images/sponsor/hlp-galleria.jpg';
import UnitedMediaLogo from '../../assets/images/sponsor/united-media.jpg';
import ChaupalLogo from '../../assets/images/sponsor/chaupal.jpg';
import TribuneLogo from '../../assets/images/sponsor/tribune.jpg';
import ZeePunjabi from '../../assets/images/sponsor/zee-news-punjabi.jpg';
import ZeePhh from '../../assets/images/sponsor/zee-news-phh.jpg';


import '../../assets/css/sponsor-style.css';

const sponsor = [
    {
        id: 1,
        title: 'Title Partner - HLP',
        img: HlpLogo,
        link: 'https://www.hlpgalleria.com/',
    },
    { id: 2, title: 'Presenting Partner - Amity University', img: AmityLogo, link: 'https://amity.edu/mohali/' },
    { id: 3, title: 'Co Powered By -  Mahindra', img: XuvLogo, link: 'https://auto.mahindra.com/suv/xuv3xo/X3XO.html?utm_medium=cpc&utm_source=brandsearch&utm_content=RSA1&adgroup=brand&utm_campaign=xuv3xo_brand_search_JFM25&gad_source=1&gbraid=0AAAAADN8zyMDFjAq_m-D3tDsYQEIUKxdK' },
    { id: 4, title: 'Co Powered By - GD Goenka', img: GdGoenkaLogo, link: 'https://gdgoenkamohali.com/' },
    { id: 5, title: 'Josh Partner - Swaraj', img: SwarajLogo, link: 'https://www.swarajtractors.com/' },
    { id: 6, title: 'Food Partner - Pizza Hut', img: PizzaHutLogo, link: 'https://www.pizzahut.co.in/' },
    { id: 7, title: 'Print Partner - The Tribune', img: TribuneLogo, link: 'https://www.tribuneindia.com/' },
    { id: 8, title: 'Entertainment Partner - Chaupal', img: ChaupalLogo, link: 'https://chaupal.tv/' },
    { id: 9, title: 'Outdoor Partner - United Media', img: UnitedMediaLogo, link: 'https://www.instagram.com/unitedmediaeventsindia?igsh=MXZsbDhubTAybDg3NQ==' },
    { id: 10, title: 'TV Entertainment Partner - Zee Punjabi', img: ZeePunjabi, link: 'https://youtube.com/@punjabizee?si=H7VT8GQIXxs_QGay' },
    { id: 11, title: 'TV News Partner - Zee PHH', img: ZeePhh, link: 'https://zeenews.india.com/hindi/zeephh' }
    
];

const Sponsor = () => {
    useEffect(() => {
        document.title = 'Sponsor - Bhangra Premier League - Red FM';
    }, []);

    return (
        <>
            <HeaderSubPages pageName='Sponsor' />
            {/* <div className="d-flex align-items-start justify-content-center vh-100">
                        <h1 className="text-white text-center mt-5">Watch out this space for update</h1>
                    </div> */}
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='sponsor-main-box'>
                                <div className='row g-0'>
                                    {sponsor.map((item) => {
                                        return (
                                            <div className='col-6' key={item.id}>
                                                <div className='sponsor-single-box'>
                                                    <div className='sponsor-img-rounded'>
                                                        <a href={item.link} target='_blank' rel='noreferrer'>
                                                            <img
                                                                src={item.img}
                                                                className='img-fluid'
                                                                alt={item.title}
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className='content-bottom'>
                                                        <a href={item.link} target='_blank' rel='noreferrer'>
                                                            <h3 className='sponsor-name'>{item.title}</h3>
                                                        </a> 
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Sponsor;
