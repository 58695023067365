import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';

const TermsAndConditions = () => {
    useEffect(() => {
        document.title = 'Terms & Conditions - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='Terms & Conditions' />
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='subpage-main-box'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='subpage-single-box'>
                                            <div className='content-bottom'>
                                                <h1 className='subpage-title'>Bhangra Premier League – Season 2</h1>
                                                <p class="mt-2 text-gray-600">The Bhangra Premier League – Season 2 is the biggest Bhangra battle across Punjab. This high-energy competition brings together the best Bhangra teams, offering audiences a vibrant mix of traditional Bhangra and fusion performances that blend Bhangra moves with hip-hop and other musical styles.</p>

                                                <p class="mt-4 text-gray-600">Bhangra Premier League – Season 2 invites participants from across India to showcase their talent by uploading their performance videos on the official microsite –
                                                    <a href="https://bhangrapremierleague.in/" class="text-blue-500 underline">https://bhangrapremierleague.in/</a>. This event is organized, managed, and operated by South Asia FM Limited, a company incorporated under the Companies Act, 1956, with its corporate office at Murasoli Maran Towers, 73 MRC Nagar Main Road, MRC Nagar, Chennai, Tamil Nadu – 600028, India (hereinafter referred to as the "Organizer").</p>
                                                <p>The competition consists of both virtual and physical events. Participants must submit video links for selection by the judges, and the shortlisted teams will be invited to perform at the finale in Punjab, where the ultimate winner will be decided. By registering on the Bhangra Premier League – Season 2 website and submitting a video link, participants officially enter the event (hereinafter referred to as the "Participant(s)").</p>

                                                <hr />

                                                <h3>Agreement to Terms and Conditions</h3>
                                                <ol type={'a'}>
                                                    <li>By participating in this event, Participants fully and unconditionally agree to abide by the Terms and Conditions available on the Bhangra Premier League – Season 2 website.</li>
                                                    <li>Event details can be found in the FAQs section on the official website.</li>
                                                    <li>The Organizer reserves the right to modify, postpone, alter, or cancel the event at any time.</li>
                                                    <li>The decision of the Organizer is final, binding, and non-contestable.</li>
                                                </ol>
                                                <hr />
                                                <h3>Eligibility & Participation</h3>
                                                <ol type={'a'}>
                                                    <li>The event is open to Indian citizens aged eight (8) years or above as of the date of video submission. For group performances, the submission indicates that the Participant has obtained consent from all performers in the video. If minors are involved, the Participant warrants that they have the consent of a parent or legal guardian.</li>
                                                    <li>Any content that is offensive, indecent, discriminatory, vulgar, defamatory, sexually explicit, or against public morality will lead to immediate disqualification at the sole discretion of the Organizer. Participants must avoid songs with violent themes, hate speech, explicit drug references, or profanity.</li>
                                                    <li>Online auditions will commence on <span class="fw-bold">18th February 2025</span> at <span class="font-semibold">3:00 PM</span> and continue for twenty-eight (28) days until <span class="font-semibold">17th March 2025</span> at <span class="font-semibold">11:00 PM</span>.</li>
                                                    <li>The finale venue will be chosen by the Organizer, who reserves the right to change the venue, date, and time of the auditions at any point.</li>
                                                    <li>Participants must submit video links from YouTube, Facebook, or Instagram in the appropriate category. Each Participant can register only once and must upload a single public video on one of the platforms. specified platforms before submitting the link at registration. Incomplete or erroneous submissions will be considered invalid. Videos must not be older than six (6) months from the date of registration. Videos should be recorded in a well-lit setting, with the Participant clearly visible and the camera steady.</li>
                                                    <li>The top five (5) participants from each category will be selected by the judges and Organizer. Shortlisted participants will be notified via the contact details provided during registration. These finalists will perform at the grand finale in <strong> Chandigarh on 22nd March 2025.</strong></li>
                                                    <li>Registration does not guarantee selection or participation. The selection process is at the sole discretion of the Organizer and is subject to availability of time and venue. Applicants and their legal guardians waive any right to dispute the selection process or non-selection.</li>
                                                </ol>
                                                <hr />
                                                <h3>Intellectual Property</h3>
                                                <ol type={'a'}>
                                                    <li>All rights, titles, and interests, including but not limited to intellectual property rights in the event materials and any responses received, will remain the exclusive property of the Organizer.</li>
                                                    <li>All materials submitted in connection with the event (whether written, audio, electronic, visual, or any combination thereof) will become the sole property of the Organizer upon submission. Participants agree to execute any necessary assignments or documents required to transfer full ownership to the Organizer.</li>
                                                    <li>The Organizer reserves the right to use, reproduce, publish, distribute, and modify any submitted materials for promotional or commercial purposes without further compensation to Participants.</li>
                                                </ol>
                                                <hr />
                                                <p>The above Terms & Conditions outline the key guidelines for participation. Participants are encouraged to visit the Bhangra Premier League – Season 2 website for any additional updates or clarifications.</p>
                                                <hr />
                                                <h3>General Conditions</h3>
                                                <ol type='1'>
                                                    <li>Nor it is transferable.</li>
                                                    <li>The prize is subject to the applicable laws of India. The prize would be delivered/ handed over to the Winner only upon proper identification and verification of the Participant to the full satisfaction of the Organizer.</li>
                                                    <li>Organizers are not responsible for any errors or omissions in the terms and conditions contained herein. All information provided in the Event is provided on an "as-it-is" basis without any warranty of any kind. Organizer, its management, directors, employees, officers, affiliates or subsidiaries, agents, representatives, etc. or any of its sponsors/partners make no representations, and further disclaim themselves from all express, implied, and statutory warranties of any kind towards the Participants, and any third party with respect to accuracy, timelines, completeness, merchantability, or fitness of the Event.</li>
                                                    <li>All rights, title, and interest, including but not limited to Intellectual Property Rights in the Event and/or any other promotional material(s) shall vest solely and exclusively with the Organizer at all times. By voluntarily participating in the Event, Participant and/or Winner(s) hereby waive off all their rights to claim royalty, remuneration, or legally challenge due to any such use of their personal details by the Organizer.</li>
                                                    <li>The Participant specifically agrees that Organizers, its affiliates, its group companies, their employees, officers, directors, or any other person and/or sponsors shall not be liable for any claims/liabilities/losses (including legal fees) (including but not limited to indirect, punitive, or consequential loss) arising out of and in relation to any injury/damage/harm/loss/death/mental or emotional trauma suffered by the Participant in any manner whatsoever in connection with the Event and/or the prizes. The Organizers, its employees, officers, directors, or any other person and/or sponsors, its affiliates shall not be responsible for any consumer grievances with respect to the participation in the Event.</li>
                                                    <li>Organizers shall not be liable to perform any of its obligations under the Event or in respect of the Prize where it is unable to do so as a result of unforeseen circumstances, Force Majeure conditions such as epidemic outbreak, pandemic including but not limited to COVID-19, lockdown or circumstances beyond its reasonable control.</li>
                                                    <li>Organizers are empowered to take a decision in case of any conditions, circumstances, and scenarios beyond these terms and conditions of the Event.</li>
                                                    <li>Any deterioration in the physical or mental health of the participants during the Event shall not make the Organizer liable, where the decision of participating in the Event is purely voluntary in nature.</li>
                                                    <li>In case of any untoward incidents, any malpractices including but not limited to cheating, misrepresentation, providing false information shall be disqualified from the Event and the Organizer retains the right to take legal actions against the participants. Where repeated warnings, if any, have been issued to the participants who are at default, then the Organizer may proceed with initiation of legal proceedings, as deemed fit.</li>
                                                    <li>The Event and the terms and conditions herein shall be governed by and construed in accordance with the applicable laws in India. All matters with respect to the Event and/or prizes are subject to the exclusive jurisdiction of the courts at Delhi only. The Event shall be deemed void where prohibited by law.</li>
                                                    <li>In case of any queries with respect to the Event, please reach out to the Organizers at <a href="mailto:red.digital@redfm.in">red.digital@redfm.in</a>.</li>
                                                </ol>
                                                <p className='fw-bold'>These terms and conditions are to be read in conjunction with the general terms of use and privacy policy of the Organizer, available at – <a href='https://www.redfmindia.in/privacy-policy'>[https://www.redfmindia.in/privacy-policy]</a>.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* repeat */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TermsAndConditions;
