import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useUserContext } from '../../../context/user-context';
import service, { methods } from '../../../service';
import CrossIconWhite from '../../../assets/icons/x-white.svg';
import PlusIcon from '../../../assets/icons/plus.svg';
import HeaderSubPages from '../../../components/header/header-sub-pages';
import { useNavigate } from "react-router-dom";

const schema = yup.object({
    participant_name: yup.string().required('Participant Name is required'),
    socialLinks: yup.array().of(
        yup.object().shape({
            url: yup.string()
                .matches(/^https:\/\/[^\s]+$/i, 'Invalid URL, must start with https://')
                .required('Social Link is required'),
        })
    ).max(4, 'Maximum 4 social links allowed'),
}).required();

const ProfileEdit = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { user, setUser } = useUserContext();
    const { register, handleSubmit, formState, control } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            participant_name: user?.participant_name || '',
            socialLinks: user?.socialLinks
                ? user.socialLinks.split(',').map(link => ({ url: link.trim() }))
                : [{ url: '' }]
        }
    });

    const { errors } = formState;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'socialLinks',
    });
    const navigate = useNavigate();

    const fetchUserDetails = async () => {
        try {
            const res = await service({
                url: 'profile/participant/',
                method: methods.GET,
            });
            return res.payload;
        } catch (error) {
            toast.error(error);
            console.error(`Error :: fetchUserDetails :: ${error}`);
        }
    };
    const onParticipateDetails = async (data) => {
        console.log(data);
        setIsLoading(true);
        //delete data.contactNumber;

        // Convert socialLinks array to a comma-separated string
        const formattedSocialLinks = data.socialLinks
            .map(link => link.url.trim())
            .filter(url => url) // Remove empty values
            .join(', '); // Convert to comma-separated string

        const updatedData = {
            ...data,
            socialLinks: formattedSocialLinks, // Now it's "https://facebook.com, https://instagram.com"
        };

        try {
            const res = await service({
                url: 'profile/update/',
                method: methods.POST,
                data: updatedData,
            });
            if (res?.status === 1) {
                const user = await fetchUserDetails();
                setUser(user);
                navigate("/user/profile");
                toast.success(res?.message);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
            console.error(`Error :: onParticipateDetails :: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        document.title = 'Profile Update - Bhangra Premier League - Red FM';
    }, []);

    return (
        <>
            <HeaderSubPages pageName="Update Profile" />
            <section className="mt-3 min-h-screen">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="login-form-box pb-4">
                                <form onSubmit={handleSubmit(onParticipateDetails)} className="d-block">
                                    <input type="hidden" value="2" {...register('seasonID')} />

                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            defaultValue={user?.participant_name}
                                            className="form-control"
                                            {...register('participant_name')}
                                            placeholder=" "
                                        />
                                        <label>Participant Name</label>
                                        {errors.participant_name && (
                                            <p className="mt-2 text-white">{errors.participant_name.message}</p>
                                        )}
                                    </div>

                                    {/* Social Links */}
                                    <div className="mb-3">
                                        <p className="font-bold text-white">Social Links</p>

                                        {fields.map((link, index) => (
                                            <div key={link.id} className="d-flex align-items-center gap-3 mb-3">
                                                <div className="form-floating flex-grow-1">
                                                    <input
                                                        type="text"
                                                        {...register(`socialLinks.${index}.url`)}
                                                        className="form-control"
                                                    />
                                                    <label>Link {index + 1}</label>
                                                    {errors.socialLinks?.[index]?.url && (
                                                        <p className="mt-2 text-white">
                                                            {errors.socialLinks[index].url.message}
                                                        </p>
                                                    )}
                                                </div>
                                                {fields.length > 1 && (
                                                    <button
                                                        type="button"
                                                        onClick={() => remove(index)}
                                                        className="btn btn-warning"
                                                    >
                                                        <img src={CrossIconWhite} alt="icon" />
                                                    </button>
                                                )}
                                            </div>
                                        ))}

                                        <div className="text-center">
                                            {fields.length < 4 && (
                                                <button
                                                    type="button"
                                                    onClick={() => append({ url: '' })}
                                                    className="btn btn-warning"
                                                >
                                                    <img src={PlusIcon} alt="icon" />
                                                </button>
                                            )}
                                        </div>
                                    </div>

                                    <button
                                        className={`w-100 btn-theme btn-secondary text-center ${isLoading && 'disable-btn'}`}
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Updating...' : 'Update'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProfileEdit;
