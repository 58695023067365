import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import ArrowDownIcon from '../../assets/icons/arrow-down-s-line.svg';
import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import UserIcon from '../../assets/icons/user.svg';
import service, { methods } from '../../service';
import CrossIcon from '../../assets/icons/x.svg';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const schema = yup
    .object({
        full_name: yup.string().required('Full Name is required'),
        email: yup.string().email('Enter a valid email').required('Email is required'),
        dob: yup
            .date()
            .required('Date of birth is required')
            .max(new Date(new Date().setFullYear(new Date().getFullYear() - 8)), 'You must be at least 8 years old')
            .typeError('Invalid date format'),
        gender: yup.string().required('Gender is required'),
    })
    .required();
const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
};
const StepThree = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showConsent, setShowConsent] = useState(false);
    const [dob, setDob] = useState(null);
    const [age, setAge] = useState(null);
    const [isOpen, setIsOpen] = useState(false); // Default open
    const calendarRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const dobValue = watch('dob');

    const { handleNextStepParticipate, handleClose } = useAuthContext();
    const { setToken } = useUserContext();
    const navigate = useNavigate();

    const handleNavigateToTc = () => {
        handleClose();
        navigate('terms-and-conditions');
    };

    const handleNavigateToPP = () => {
        handleClose();
        navigate('privacy-policy');
    };

    const onRegister = async (data) => {
        setIsLoading(true);
        if (age < 8) {
            toast.error('You must be at least 8 years old to participate.');
            return;
        }
        const formattedData = {
            ...data,
            dob: Math.floor(new Date(data.dob).getTime() / 1000), // Convert to timestamp
        };
        try {
            const res = await service({
                url: 'register/',
                data: formattedData,
                method: methods.POST,
            });
            if (res?.status === 1) {
                setToken(res.payload.jwtToken);
                handleNextStepParticipate(4);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
            console.error(`Error :: onRegister :: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (dobValue) {
            const birthDate = new Date(dobValue);
            const today = new Date();
            const calculatedAge = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            const dayDiff = today.getDate() - birthDate.getDate();

            const finalAge = monthDiff < 0 || (monthDiff === 0 && dayDiff < 0) ? calculatedAge - 1 : calculatedAge;

            setAge(finalAge);
            setShowConsent(finalAge >= 8 && finalAge < 18);
        }
    }, [dobValue]);

    return (
        <div className='content-box'>
            <div className='row justify-content-center align-items-start'>
                <div className='col-lg-12'>
                    <div className='content-top mb-3'>
                        <div className='text-center' onClick={handleClose}>
                            <span className='close-login-big-dash'></span>
                        </div>
                        <h2 className='text-white'>
                            <span className='d-block'>Register</span>
                        </h2>
                        <button className='text-x' onClick={handleClose}>
                            <img src={CrossIcon} className='img-fluid' alt='cross-icon' />
                        </button>
                    </div>
                    <div className='login-form-box'>
                        <form onSubmit={handleSubmit(onRegister)} className='d-block'>
                            <div className='form-floating input-with-icon mb-3'>
                                <img src={UserIcon} className='img-fluid' alt='user-icon' />
                                <input
                                    type='text'
                                    className='form-control'
                                    {...register('full_name')}
                                    placeholder=' '
                                    autoComplete='off'
                                />
                                <label>Full Name</label>
                                {errors.full_name && <p className='mt-2 text-white'>{errors.full_name.message}</p>}
                            </div>

                            <div className='form-floating input-with-icon mb-3'>
                                <img src={UserIcon} className='img-fluid' alt='user-icon' />
                                <input type='email' className='form-control' {...register('email')} placeholder=' ' autoComplete='off' />
                                <label htmlFor='EmailId'>Email address</label>
                                {errors.email && <p className='mt-2 text-white'>{errors.email.message}</p>}
                            </div>
                            <div className="form-floating input-with-icon mb-3">
                                <img src={UserIcon} className="img-fluid" alt="user-icon" />
                                <input
                                    type="text" // Change from "date" to "text"
                                    className="form-control"
                                    {...register('dob')}
                                    placeholder=" "
                                    readOnly // Ensures manual input is disabled
                                    value={dob ? formatDate(dob) : ""}
                                    onClick={() => setIsOpen(true)} // Opens calendar when clicked
                                />
                                <label htmlFor="dobId">Date of Birth</label>
                                {errors.dob && <p className="mt-2 text-white">{errors.dob.message}</p>}
                            </div>

                            {isOpen && (
                                <div className="form-floating input-with-icon mb-3" ref={calendarRef}>
                                    <Calendar
                                        onChange={(value) => {
                                            setDob(value);
                                            setValue('dob', value);
                                            setIsOpen(false); // Hide after selecting date
                                        }}
                                        value={dob}
                                        maxDate={new Date()}
                                    />
                                    {errors.dob && <p className="mt-2 text-white">{errors.dob.message}</p>}
                                </div>
                            )}
                            {showConsent && (
                                <div className='form-check mb-2'>
                                    <label className='form-check-label d-flex gap-2'>
                                        <input type='checkbox' className='form-check-input' {...register('consent', { required: 'You must provide consent' })} />

                                        <small>
                                            I authorize my child to participate in the contest & agree to the {' '}
                                            <span className='text-yellow cursor-pointer' onClick={handleNavigateToTc}>
                                                Terms & Conditions
                                            </span>{' '}
                                            and <Link to='/privacy-policy' className='text-yellow' onClick={handleNavigateToPP}>Privacy Policy</Link>
                                        </small>
                                    </label>
                                    {errors.consent && <p className='mt-2 text-danger'>{errors.consent.message}</p>}
                                </div>
                            )}
                            <div className='form-floating mb-3'>
                                <img src={ArrowDownIcon} className='arrow-select' alt='ArrowDownIcon' />
                                <select className='form-select' {...register('gender')}>
                                    <option value=''>Select Gender</option>
                                    <option value={'1'}>
                                        {'Male'}
                                    </option>
                                    <option value={'2'}>
                                        {'Female'}
                                    </option>
                                    <option value={'3'}>
                                        {'Others'}
                                    </option>
                                </select>
                                <label>Gender</label>
                                {errors.gender && (
                                    <p className='mt-2 text-white'>{errors.gender.message}</p>
                                )}
                            </div>

                            {/* <div className='form-floating mb-3'>
                                <img src={ArrowDownIcon} className='arrow-select' alt='ArrowDownIcon' />
                                <select className='form-select' {...register('auditionDetails')}>
                                    <option value=''>Select Preferred Audition Location</option>
                                    {auditionDetails.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value} disabled={item.disabled}>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>Preferred Audition Location</label>
                                {errors.auditionDetails && (
                                    <p className='mt-2 text-white'>{errors.auditionDetails.message}</p>
                                )}
                            </div> */}

                            <button
                                className={`w-100 btn-theme btn-secondary ${isLoading && 'disable-btn'}`}
                                type='submit'
                                disabled={isLoading}
                            >
                                Next
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepThree;
