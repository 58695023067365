import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import React, { useState } from 'react';

// import BannerHomeDesktop from '../../assets/images/banner-coming-soon-season2-desktop.jpg';
// import BannerTwoDesktop from '../../assets/images/banner-two-desktop.png';
// import BannerTwoMobile from '../../assets/images/banner-two-mobile.png';
// import Bhangra from '../../assets/images/banner-coming-soon-season2-mobile.jpg';

import Bhangra from '../../assets/images/home-top-banner/bpl-home-top-banner-season-2-mobile.png'
import BannerHomeDesktop from '../../assets/images/home-top-banner/bpl-home-top-banner-season-2-desktop.png';

import AnnouncementD from '../../assets/images/home-top-banner/announcement-desktop.jpg'
import AnnouncementM from '../../assets/images/home-top-banner/announcement-mobile.jpg';

import HowToRegisterD from '../../assets/images/home-top-banner/how-to-register-desktop.jpg'
import HowToRegisterM from '../../assets/images/home-top-banner/how-to-register-mobile.jpg';

import WinnerSeason2D from '../../assets/images/home-top-banner/combined-winners-season-2-desktop.jpg'
import WinnerSeason2M from '../../assets/images/home-top-banner/combined-winners-season-2-mobile.jpg';

import SoloDesktop from '../../assets/images/home-top-banner/winner-2025/solo-desktop.jpg'
import SoloMoible from '../../assets/images/home-top-banner/winner-2025/solo-mobile.jpg'

import GroupMusicDesktop from '../../assets/images/home-top-banner/winner-2025/group-music-desktop.jpg'
import GroupMusicMoible from '../../assets/images/home-top-banner/winner-2025/group-music-mobile.jpg'


import GroupLiveDesktop from '../../assets/images/home-top-banner/winner-2025/group-live-desktop.jpg'
import GroupLiveMoible from '../../assets/images/home-top-banner/winner-2025/group-live-mobile.jpg'

// import dWinnerBanner1 from '../../assets/images/winners/category-a-position-1.jpg';
// import dWinnerBanner2 from '../../assets/images/winners/category-a-position-2.jpg';
// import dWinnerBanner3 from '../../assets/images/winners/category-b-position-1.jpg';
// import dWinnerBanner4 from '../../assets/images/winners/category-b-position-2.jpg';

// import mWinnerBanner1 from '../../assets/images/winners/category-a-position-1-m.jpg';
// import mWinnerBanner2 from '../../assets/images/winners/category-a-position-2-m.jpg';
// import mWinnerBanner3 from '../../assets/images/winners/category-b-position-1-m.jpg';
// import mWinnerBanner4 from '../../assets/images/winners/category-b-position-2-m.jpg';

import dAllWinners from '../../assets/images/winners/all-winners-bpl-2024.jpg';
import mAllWinners from '../../assets/images/winners/all-winners-bpl-2024-m.jpg';
import VideoPlayerModal from './video-player-modal';

const TopBanner = () => {
    const [show, setShow] = useState(false);
        const [url, setUrl] = useState('');
    
        const handleClose = () => {
            setShow(false);
            setUrl('');
        };
    
        const handleOpen = (url) => {
            setUrl(url);
            setShow(true);
        };
    return (
        <>
            <Swiper
            modules={[Pagination, Autoplay]}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            className='swiper-home'
        >
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={SoloMoible} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={SoloDesktop} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={GroupMusicMoible} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={GroupMusicDesktop} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={GroupLiveMoible} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={GroupLiveDesktop} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={WinnerSeason2M} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={WinnerSeason2D} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={Bhangra} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={BannerHomeDesktop} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='cursor-pointer' onClick={() => handleOpen('https://www.youtube.com/watch?v=oh0JJCW7-vc')}>
                        <div className='text-center d-lg-none d-block'>
                            <img src={HowToRegisterM} alt='img' className='img-fluid w-100 ' />
                        </div>
                        <div className='text-center d-none d-lg-block'>
                            <img src={HowToRegisterD} alt='img' className='img-fluid w-100 ' />
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='cursor-pointer' onClick={() => handleOpen('https://www.youtube.com/watch?v=fWZovYoMaBM')}>
                        <div className='text-center d-lg-none d-block'>
                            <img src={AnnouncementM} alt='img' className='img-fluid w-100 ' />
                        </div>
                        <div className='text-center d-none d-lg-block'>
                            <img src={AnnouncementD} alt='img' className='img-fluid w-100 ' />
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={mAllWinners} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={dAllWinners} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
        {show && <VideoPlayerModal show={show} handleClose={handleClose} url={url} />}
        </>
    );
};

export default TopBanner;
