import Accordion from 'react-bootstrap/Accordion';
import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';
import ImgJudge1 from '../../assets/images/judge-p-1.jpg';
import ImgJudge2 from '../../assets/images/judge-p-2.jpg';
import ImgJudge3 from '../../assets/images/judge-p-3.jpg';
import ImgJudge4 from '../../assets/images/judge-p-4.png';
import ImgJudge7 from '../../assets/images/judges/judge-p-7.jpg';
import ImgJudge8 from '../../assets/images/judges/judge-p-8.jpg';
import ImgJudge9 from '../../assets/images/judges/judge-p-9.jpg';
import ImgJudge10 from '../../assets/images/judges/judge-p-10.jpg';
import '../../assets/css/faq-style.css';

const Judges = () => {
    useEffect(() => {
        document.title = 'Judges - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='Judges' />
            <section className='mt-3'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10'>
                            <div className='subpage-main-box'>
                                <div className='row mb-5'>
                                    <div className='col-lg-12'>
                                        <div className='text-center py-4 border rounded border-white mb-4'>
                                            <h1 className='text-warning fw-bold'>2025</h1>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                       
                                        <Accordion defaultActiveKey='0' className='faq-accordion accordionJudges' id='accordionJudges2025'>
                                            <Accordion.Item eventKey='0'>
                                                <Accordion.Header>
                                                    <div className='rounded-circle border border-3 border-white me-2'>
                                                        <img
                                                            src={ImgJudge7}
                                                            className='rounded-circle'
                                                            width={110}
                                                            alt='pf'
                                                        />
                                                    </div>
                                                    <span className='text-uppercase'>Supneet Singh </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        Supneet Singh is a dynamic Punjabi turbaned model, actor, singer, and above all, a passionate Bhangra coach. As the founder of Ashke Folk Dance & Music Academy in Ludhiana, he has dedicated his life to preserving and promoting the rich tradition of Bhangra. A true ambassador of the art, you’ll often find him at Bhangra festivals and events—whether coaching the next generation of dancers, judging fierce competitions, or representing the culture he loves.
                                                    </p>
                                                    <p>
                                                        Since 2017, Supneet has been lighting up Punjabi music videos, starting with the hit Bhangra Giddha track alongside Nimrat Khaira and later starring in Amrinder Gill's movie Ashke. His deep-rooted connection to Bhangra is evident in everything he does, making him not just a performer but a driving force in the Punjabi music and dance scene. With an infectious energy and a heart full of passion for Bhangra, Supneet is an artist, educator, and cultural ambassador all rolled into one.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey='1'>
                                                <Accordion.Header>
                                                    <div className='rounded-circle border border-3 border-white me-2'>
                                                        <img
                                                            src={ImgJudge8}
                                                            className='rounded-circle'
                                                            width={110}
                                                            alt='pf'
                                                        />
                                                    </div>{' '}
                                                    <span className='text-uppercase'>Sonu Pardhan </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Sonu Pardhan is the driving force behind Bhangra in Punjab, serving as both the Bhangra Coordinator and Coach. A masterful choreographer, Sonu has shaped over 5000 video albums, contributed to more than 100 Punjabi films, and worked on 25+ Bollywood blockbusters and 5 South Indian films, along with serials and advertisements.</p>
                                                    <p>His unparalleled talent has seen him perform in the first video of Diljit Dosanjh, work with Gippy Grewal, and choreograph the legendary Aamir Khan in Rang De Basanti. He's also collaborated with director Imtiaz Ali and has been part of countless projects that celebrate Bhangra’s energy and spirit. But Sonu is more than just a choreographer—he's a mentor, ensuring that Bhangra artists and dance groups across Punjab receive the right platforms to showcase their talent, often helping them feature in prominent films.</p>
                                                    <p>A true legend in the world of Bhangra, Sonu Pardhan's influence transcends choreography—he’s a champion for the art form, bringing it to the forefront of the global stage.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey='2'>
                                                <Accordion.Header>
                                                    <div className='rounded-circle border border-3 border-white me-2'>
                                                        <img
                                                            src={ImgJudge9}
                                                            className='rounded-circle'
                                                            width={110}
                                                            alt='pf'
                                                        />
                                                    </div>
                                                    <span className='text-uppercase'>Hardeep Singh</span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        With over a decade of experience in the world of Bhangra, Hardeep Singh is a highly respected name in the industry. A trained dancer and choreographer, Hardeep has dedicated his career to perfecting and sharing the art of Bhangra across India and beyond.
                                                    </p>
                                                    <p>
                                                        Having conducted more than 50 workshops throughout the country, he has successfully mentored over 600 students, inspiring and shaping the careers of the next generation of dancers. His passion for Bhangra and its promotion is evident in his work as the founder of Stage One Studios and Stage One Entertainment, two platforms dedicated to bringing Bhangra to the mainstream and supporting Bhangra artists.
                                                    </p>
                                                    <p>Hardeep's expertise extends to choreography for high-profile artists, including the iconic Satinder Sartaaj, and his reputation has earned him a place as a mentor on several popular dance reality shows.</p>
                                                    <p>With a deep-rooted belief in Bhangra's potential as a commercial dance form, Hardeep continues to push boundaries and elevate the global recognition of this traditional art form.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey='3'>
                                                <Accordion.Header>
                                                    <div className='rounded-circle border border-3 border-white me-2'>
                                                        <img
                                                            src={ImgJudge10}
                                                            className='rounded-circle'
                                                            width={110}
                                                            alt='pf'
                                                        />
                                                    </div>
                                                    <span className='text-uppercase'>RAJBIR MALHI</span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        Rajbir Malhi is a well-known name that every Bhangra enthusiast would have heard of. Currently working as an Assistant Professor at DIPS College of Education in Hoshiarpur. Rajbir Malhi has delivered a lot of paper presentations at national as well as international conferences & seminars.
                                                    </p>
                                                    <p>
                                                        He has 24 years of experience in Bhangra. He has been a part of various Zonal, Inter-zonal, as well as National Youth Festivals and has also adjudged Best Dancers at various youth festivals. Since 2011 he has been judging all zonal and inter-zonal bhangra competitions in Universities across Punjab. He has also judged various national and international Bhangra festivals like North America’s largest festival Flower City Bhangra at Brampton, World Folk Festival at Vancouver and Bhangra Youth Festivals. He is being appreciated by the Government of Canada for contributing to the field of Folk dances and Bhangra. He is also writing a book on Bhangra.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        </Accordion>
                                    </div>
                                </div>
                                {/* year 2024 data start */}
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='text-center py-4 border rounded border-white mb-4'>
                                            <h1 className='text-warning fw-bold'>2024</h1>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        {/* defaultActiveKey='0' */}
                                        <Accordion className='faq-accordion accordionJudges' id='accordionJudges'>
                                            <Accordion.Item eventKey='0'>
                                                <Accordion.Header>
                                                    <div className='rounded-circle border border-3 border-white me-2'>
                                                        <img
                                                            src={ImgJudge3}
                                                            className='rounded-circle'
                                                            width={110}
                                                            alt='pf'
                                                        />
                                                    </div>
                                                    <span>PARMJIT SINGH SIDHU (Pammi Bai)</span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        The Bhangra King, Parmjit Singh Sidhu, popularly known as Pammi
                                                        Bai, is a well-renowned folk artist and Bhangra icon with 35
                                                        years of practical experience in the cultural activities of
                                                        Punjab. He coached Punjab (Bhangra) for the Asiad-82 games in
                                                        Delhi and has organized national as well as international
                                                        cultural events, earning prestigious awards at both levels. The
                                                        Sangeet Natak Academy honored him with the "National Folk Music
                                                        Award 2015," presented by the President of India, Sh. Pranab
                                                        Mukherjee. In 2009, he received the Shiromani Punjabi Lok Gayak
                                                        Award, one of the most significant accolades in his career.
                                                        Furthermore, Parmjit Singh Sidhu was granted a fellowship by
                                                        Punjabi University, Patiala, in the field of Art and Culture in
                                                        Punjab. His international recognition includes receiving the Art
                                                        and Culture Award in Germany and the Waris Shah Award in 2002 in
                                                        London. His contributions to Punjabi culture have garnered
                                                        global appreciation.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey='1'>
                                                <Accordion.Header>
                                                    <div className='rounded-circle border border-3 border-white me-2'>
                                                        <img
                                                            src={ImgJudge1}
                                                            className='rounded-circle'
                                                            width={110}
                                                            alt='pf'
                                                        />
                                                    </div>{' '}
                                                    <span className='text-uppercase'>Sonu Pardhan</span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    Sonu Pardhan is the Bhangra Coordinator in Punjab and the Bhangra
                                                    Coach. As a Bhangra choreographer in Punjabi he has done 5000+ video
                                                    albums, 100+ Punjabi films, 25+ Bollywood films, 5 South Indian
                                                    films, serials, ads. Sonu has performed in the first video of Diijit
                                                    dosanjh, performed for gippy Grewal, choreographed Amir Khan in Rang
                                                    de Basanti, Imtiaz ali, the list is endless. He is not only training
                                                    the bhangra groups but also making sure the bhangra artists and
                                                    groups across Punjab get right platforms and to be featured in
                                                    films.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey='2'>
                                                <Accordion.Header>
                                                    <div className='rounded-circle border border-3 border-white me-2'>
                                                        <img
                                                            src={ImgJudge2}
                                                            className='rounded-circle'
                                                            width={110}
                                                            alt='pf'
                                                        />
                                                    </div>
                                                    <span className='text-uppercase'>Hardy Singh</span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        Hardeep Singh, a.k.a. Hardy Singh is Dubai based entrepreneur, bhangra dancer and influencer and founder of the only happening ‘Bhangra’ crew in the Middle East, Pure Bhangra.
                                                    </p>
                                                    <p>
                                                        He has been performing professionally for over 15 years in this
                                                        dance form and has been taking Bhangra workshops all around the
                                                        world! A professional trainer, he has also travelled around the
                                                        world for judging various Bhangra competitions such as ‘Bhangra
                                                        Dance Off’ in Malaysia, ‘Bhangra Auckland Da’ in New Zealand,
                                                        ‘Youth Fest’ for Middlesex University and more in UAE. Hardy
                                                        Singh is the only Sikh from the Middle East to be included in
                                                        the Sikh Group’s Top 100 most Powerful and Influential Sikhs
                                                        un-der 30 in the world.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey='3'>
                                                <Accordion.Header>
                                                    <div className='rounded-circle border border-3 border-white me-2'>
                                                        <img
                                                            src={ImgJudge4}
                                                            className='rounded-circle'
                                                            width={110}
                                                            alt='pf'
                                                        />
                                                    </div>
                                                    <span className='text-uppercase'>RAJBIR MALHI</span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        Rajbir Malhi is a well-known name that every Bhangra enthusiast
                                                        would have heard of. Currently working as an Assistant Professor
                                                        at DIPS College of Education in Hoshiarpur. Rajbir Malhi has
                                                        delivered a lot of paper presentations at national as well as
                                                        international conferences & seminars.
                                                    </p>
                                                    <p>
                                                        He has 24 years of experience in Bhangra. He has been a part of
                                                        various Zonal, Inter-zonal, as well as National Youth Festivals
                                                        and has also adjudged Best Dancers at various youth festivals.
                                                        Since 2011 he has been judging all zonal and inter-zonal bhangra
                                                        competitions in Universities across Punjab. He has also judged
                                                        various national and international Bhangra festivals like North
                                                        America’s largest festival Flower City Bhangra at Brampton,
                                                        World Folk Festival at Vancouver and Bhangra Youth Festivals. He
                                                        is being appreciated by the Government of Canada for
                                                        contributing to the field of Folk dances and Bhangra. He is also
                                                        writing a book on Bhangra.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Judges;
