import React, { useEffect } from 'react';

import HeaderSubPages from '../../components/header/header-sub-pages';
// import ImgAboutUs from '../../assets/images/about-top.jpg';
import PlayIcon from '../../assets/icons/video-icon.png';
import ReactPlayer from 'react-player/lazy';
function AboutUs() {
    useEffect(() => {
        document.title = 'About Us - Bhangra Premier League - Red FM';
    }, []);
    return (
        <>
            <HeaderSubPages pageName='About Us' />
            <section>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='subpage-main-box'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='subpage-single-box'>
                                            <div className='img-box'>
                                                {/* <img src={ImgAboutUs} className='w-100' alt='pf' /> */}
                                                <div className='video-container'>
                                                    <ReactPlayer
                                                        url={'https://youtu.be/fWZovYoMaBM'}
                                                        light={true}
                                                        width='100%'
                                                        height='300px'
                                                        className='react-player'
                                                        loading='lazy'
                                                        controls={true}
                                                        playIcon={<img src={PlayIcon} alt='Play Icon' width='40px' />}
                                                        playing={true}
                                                        onContextMenu={(e) => e.preventDefault()}
                                                    />
                                                </div>
                                            </div>
                                            <div className='content-bottom'>
                                                <h1 className='subpage-title'>About Bhangra Premiere league</h1>
                                                <p>Catchy beats, whirls of color, and unbeatable energy: These three phrases encapsulate the essence of all that is BHANGRA.</p>
                                                <p>It is a lively and energetic dance style originating in Punjab. The dance is full of life and vitality, reflecting the exuberant spirit of Punjabi culture. With its lively footwork, high jumps, joyful expressions and spirited movements, Bhangra captures the essence of Punjab's vibrant and celebratory spirit. Accompanied by the rhythmic beats of the dhol, the traditional drum, and the energetic tunes of folk songs, Bhangra creates an electrifying atmosphere that enthrals both performers and spectators alike. It's a blend of different folk dances from across the region, including Sammi, Jhummar, Luddi, Giddha, Dhamaal, Sialkot, and many more.</p>
                                                <p>Bhangra premiere league is the biggest bhangra battle across Punjab. This battle between the best bhangra teams, giving the audience a taste of traditional bhangra, as well as fusion dances in which bhangra moves are set to hip-hop and other genres of music.    </p>
                                                <p>Bhangra premiere league invites people to participate across India by uploading their videos. The audition battle will happen across Punjab. </p>
                                                <h2 className='h4'>How to Participate</h2>
                                                <ul>
                                                    <li>Log in to https://bhangrapremierleague.in/</li>
                                                    <li>To register create your account.</li>
                                                    <li>Click on the participate button.</li>
                                                    <li>Fill your Name, Mobile number, e-mail id etc.</li>
                                                    <li>Select your category.</li>
                                                    <li>Paste your video link (Youtube, Facebook or Instagram link,) and submit your registration.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* repeat */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUs;
